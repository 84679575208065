export const TEXTS = {
    ge: {
      home: "მთავარი",
      about: "ვინ ვართ ჩვენ",
      contact: "კონტაქტი",
      
    },
  
    en: {
      home: "HOME",
      about: "About",
      contact: "Contact",
    },
  };
