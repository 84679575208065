const cardsData = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510142/IMG_1695_ff9zd7.jpg",
    category: "PIXEL სტუდიო",
    subcategories: [
      {
        id: "19",
        name: "საიმიჯო ",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507012/IMG_2623_yirath.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2481_bmreib.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2573_mro5qn.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507006/IMG_2345_wmxyol.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507006/IMG_2313_zamdwt.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2386_g4n5wj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506998/IMG_2283_ralnqk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506999/IMG_2158_zz3cdb.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506998/IMG_2014_kydyp5.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506991/IMG_1700_avtini.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506991/IMG_1725_jvavtm.jpg",
        ],
      },
      {
        id: "11",
        name: "საბავშვო ",
        photos: [
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060810/03_drsifz.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060806/IMG_9042_l3dk8c.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060804/IMG_6951_tnxnxl.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060804/IMG_6951_tnxnxl.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060804/IMG_5759_a2nxv0.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060804/IMG_4850_yink33.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060803/IMG_4834_kzygaz.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060803/_MG_8458_iuky1w.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060802/_MG_0154_tsjezy.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738060802/_MG_0155_plehpa.jpg",
        ],
      },

      {
        id: "12",
        name: "საორსულო",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510402/IMG_5285_psenxo.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510507/IMG_5349_jnfimz.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510302/IMG_2246_zofgox.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510301/02_nhxkay.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510300/IMG_5271_gq1tlu.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510297/IMG_5263_g759v5.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510288/01_ijx12k.jpg",
        ],
      },
      {
        id: "33",
        name: "სტუდიური პროფესიული",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509817/IMG_9807_cq9ycq.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509814/IMG_9812_copy_zp04sr.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509814/IMG_9801_copy_hh3lc6.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509808/IMG_9577_svhqmu.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509804/IMG_9489_neiviv.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509800/IMG_7400_lspsbw.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509799/IMG_9370_ldy5lv.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509788/IMG_0341_mn2bfk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509791/IMG_1499_eaxskz.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509796/9666_qqdcfv.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509786/_MG_0054_elegrj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509787/9707_kufzml.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509782/_MG_0009_qfjkfy.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509781/01_-_Copyfgb_clodbz.jpg",
        ],
      },

      {
        id: "13",
        name: "ინდივიდუალური",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510826/IMG_9602_2_rsahgc.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510809/IMG_61552_eqancj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510808/IMG_9306_rsaauv.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510808/IMG_9827_ro7btk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510802/IMG_8887_s6yk0y.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510781/IMG_6697_pfttxh.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510780/IMG_6694_uuvvbe.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510762/IMG_6648_kpyvla.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510760/IMG_6352_wupcan.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510764/IMG_6679_rktplc.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510753/IMG_6194_xdws3r.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510757/IMG_6375_ir6aya.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510759/IMG_6643_ibvmeu.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510732/IMG_1293_xzi4mk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510721/IMG_0445_kr12eb.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510720/2_khatvs.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510711/_MG_4920_xkz5ef.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510711/_MG_4988_etmy79.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510716/_MG_7697_pxb0za.jpg",
        ],
      },
      {
        id: "15",
        name: "წყვილის ფოტოსესია",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510918/IMG_3779_dkqnl5.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510916/IMG_3994_yp1hzo.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510915/IMG_3851_xd7jhj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510904/IMG_3797_tbtmwd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510903/IMG_3809_me9a2z.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510907/IMG_3764_prtcia.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510898/IMG_3751_n5uz4w.jpg",
        ],
      },

      {
        id: "10",
        name: "საახალწლო ",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510163/IMG_2383_lw4aud.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510157/IMG_1591_yhe0mt.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510157/IMG_2381_zxzqrd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510156/IMG_2345_zuqigd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510155/IMG_2183_lzsbjl.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510154/IMG_1718_bsfx5v.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510153/IMG_2159_ytvsxl.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510150/12%E1%83%99%E1%83%98_newggn.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510149/IMG_2151_rffplt.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510149/IMG_1974_cjxtip.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510142/IMG_1695_ff9zd7.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510143/IMG_1551_qdd8yx.jpg",
        ],
      },
    ],
  },

  {
    id: "2",
    image:
      "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508068/_MG_9117_xw4xpo.jpg",
    category: "PIXEL ივენთი",
    subcategories: [
      {
        id: "21",
        name: "ბანკეტი",
        photos: [
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061351/IMG_9477_-_Copy_hppcj5.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061346/IMG_7581_jrbgld.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061344/IMG_0470_xqkfba.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061344/IMG_6425_xspnpv.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061343/IMG_4124_sjsxlp.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061340/IMG_2435_gzw7im.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061339/IMG_2120_mkki4i.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061338/DJI_0537_cgy4q3.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061338/IMG_1682_xeyhdo.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061336/_MG_6431_is51xi.jpg",
        ],
      },
      {
        id: "22",
        name: "კონცერტი/ივენთი",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508448/viber_image_2021-12-11_10-18-39-913_rxogea.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508433/viber_image_2021-12-11_10-15-34-343_uopldm.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508433/viber_image_2021-12-11_10-12-55-407_bhypck.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508431/IMG_1173_nq2jzr.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508432/IMG_1200_e3x4fn.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508428/IMG_1043_ccbyai.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508430/IMG_1114_ch4yxl.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508424/IMG_0844_qdnvgi.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508096/03_ydmj0m.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508096/IMG_0778_n6ha3k.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508081/_MG_9387_seueal.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735508068/_MG_9117_xw4xpo.jpg",
        ],
      },
      {
        id: "23",
        name: "ნათლობა",
        photos: [
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061143/_MG_1123_h0vwm2.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061141/_MG_1111_hjzsuo.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061141/_MG_1175_suybhw.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061139/_MG_1192_dcojat.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061139/_MG_1122_fyxckl.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061138/_MG_1176_ll3hkc.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061138/_MG_1176_ll3hkc.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061138/_MG_1171_wbjp9w.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061136/_MG_1113_u3t58v.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061136/_MG_1106_k04dhl.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061134/_MG_1084_xhyu1n.jpg",
        ],
      },
      {
        id: "24",
        name: "გარესესია",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509217/IMG_5704_cqthdi.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509215/IMG_5689_fnsdjy.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509214/IMG_5675_mtzehu.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509214/IMG_5656_qsqifj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509212/IMG_5135_xlsctq.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509206/Firefly_Inpaint_20230809204546_zlflrj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509208/Firefly_Inpaint_20230809204546_1_svphnd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509201/Firefly_Inpaint_20230805233255_j48d5m.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509202/Firefly_Inpaint_20230809204035_ip56yg.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509201/Firefly_Inpaint_20230805233255_j48d5m.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509197/Firefly_Inpaint_20230805231725_ulluub.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509198/Firefly_Inpaint_20230805231525_iv6har.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509193/Firefly_Inpaint_20230805223821_wi1wym.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509195/Firefly_Inpaint_20230805230816_obemsq.jpg",
        ],
      },
    ],
  },

  {
    //3,5,6,9

    id: "3",
    image:
      "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438655/IMG_9290_dfszqq.jpg",
    category: "PIXEL ჰაბი",
    subcategories: [
      {
        id: "31",
        name: "საიმიჯო",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507012/IMG_2623_yirath.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2481_bmreib.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2573_mro5qn.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507006/IMG_2345_wmxyol.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507006/IMG_2313_zamdwt.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735507007/IMG_2386_g4n5wj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506998/IMG_2283_ralnqk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506999/IMG_2158_zz3cdb.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506998/IMG_2014_kydyp5.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506991/IMG_1700_avtini.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735506991/IMG_1725_jvavtm.jpg",
        ],
      },
      // {
      //   id: "32",
      //   name: "გარე-მარკეტინგული ",
      //   photos: [
      //     "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509208/Firefly_Inpaint_20230809204546_1_svphnd.jpg",
      //     "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735509214/IMG_5644_hzdl0g.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061222/MG_3260_sz99va.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061218/IMG_9996_igjfw8.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061216/IMG_9980_pijm3q.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061216/IMG_9857_ejamtd.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061213/IMG_0077_sgvhiz.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061212/IMG_0120_jsn4cc.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061211/IMG_0170_z7puef.jpg",
      //     "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061210/_MG_1386_y9jfya.jpg",
      //   ],
      // },

      {
        id: "34",
        name: "მარკეტინგული",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438682/IMG_9431_aceeeq.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438683/IMG_9469_rfvpwb.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438662/IMG_9425_z9nh4g.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438659/IMG_9330_gldrsi.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438658/IMG_9316_ld4x5j.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438653/IMG_9217_ymcgdk.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438653/IMG_3878_copy_oa89pj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438655/IMG_9290_dfszqq.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438652/IMG_3869_copy_ymqszs.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438650/IMG_3849_copy_omgcgp.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438643/IMG_1323_n41olw.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438644/IMG_1616_u0aohh.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438638/drtj_qwtqwd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438639/IMG_1402_wui6zl.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438633/IMG_0286_ov8abr.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438632/_MG_9271_bagkml.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438631/_MG_3355_-_Copy_mueaji.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438684/IMG_9609_kvcg5j.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1736438684/IMG_64482_fv4agc.jpg",
        ],
      },
      {
        id: "35",
        name: "პროდუქტი",
        photos: [
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061276/%E1%83%90%E1%83%A1_%E1%83%91%E1%83%94%E1%83%A0%E1%83%92_hmrspz.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061275/IMG_2577_skf1af.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061275/IMG_8447_qomann.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061273/IMG_2614_vvdlyd.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061272/IMG_2600_onxcho.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061271/IMG_2462_tf50gw.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061270/IMG_2395_ngofcu.jpg",
        ],
      },
      {
        id: "36",
        name: "ინტერიერი",
        photos: [
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061325/IMG_01694141_1_cc3rov.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061323/img_00040001-img_00090006_copy_rye5ek.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061318/IMG_8699-HDR_gz2car.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061316/IMG_0571__krtsanisi_ysggfj.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061315/IMG_0562__krtsanisi___y51a9x.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061313/IMG_0072__krtsanisi_1_kybgcp.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061312/IMG_0046__krtsanisi__szbgzu.jpg",
          "https://res.cloudinary.com/dwfve6x4c/image/upload/q_auto,f_auto,w_1024/v1738061309/_MG_0779_vcikd0.jpg",
        ],
      },
    ],
  },

  {
    id: "4",
    image:
      "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510851/IMG_2959_tlnhsw.jpg",
    category: "PIXEL Wedding",
    subcategories: [
      {
        id: "45",
        name: "ქორწილები",
        photos: [
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510883/IMG_6638_zujecm.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510880/IMG_6924_e8cinv.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510877/IMG_6541_hs1twr.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510872/IMG_6468_clauld.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510879/IMG_6569_duy6dj.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510872/IMG_5192_mqxtiu.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510872/IMG_6437_tqma1p.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510870/IMG_1731_jvesi6.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510869/IMG_5513_qfcom3.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510871/IMG_2870_x0jtss.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510855/IMG_4527_pzpjsd.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510858/IMG_4745_rs5vt8.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510853/IMG_3902_dpmwuc.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510839/IMG_1234_bpkswz.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510838/IMG_0984_kpglis.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510839/IMG_0100_xpxnce.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510834/IMG_0285_g8ypds.jpg",
          "https://res.cloudinary.com/dbc02mn7z/image/upload/q_auto,f_auto,w_1024/v1735510835/IMG_0729_sfk4zj.jpg",
        ],
      },
    ],
  },
];

export default cardsData;
