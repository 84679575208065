import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="about-title">ვინ ვართ ჩვენ </h1>
        <p className="about-text">
          ჩვენი ფოტო სტუდია არის სივრცე, სადაც ხარისხი, კრეატიულობა და
          პროფესიონალიზმი ერთიანდება. სტუდია გთავაზობთ სხვადასხვა ტიპის
          ფოტოგრაფიას, იქნება ეს პორტრეტული, საქორწილო, საოჯახო, პროდუქტის თუ
          კომერციული ფოტოგრაფია.📸✨
        </p>
        <p className="about-text">
          სტუდიაში ვქმნით მყუდრო და მეგობრულ გარემოს, სადაც თითოეული
          მომხმარებელი თავს კომფორტულად და განსაკუთრებულად გრძნობს. ჩვენთვის
          მნიშვნელოვანია, რომ ფოტოგადაღების პროცესი იყოს სასიამოვნო და უშუალო.
          სტუდია აღჭურვილია თანამედროვე კამერებით, განათებითა და სხვა
          პროფესიონალური ტექნიკით, რაც გვეხმარება შევქმნათ მაღალი ხარისხის
          ვიზუალური კონტენტი.
        </p>
        <p className="about-text">
          <strong>სერვისები: </strong> <br />
          • პორტრეტული ფოტოგრაფია: პროფესიული ფოტოები სხვადასხვა მიზნით –
          პირადი, სოციალური ქსელებისთვის თუ სამსახურებრივი საჭიროებისთვის.
          <br />
          • საქორწილო და ღონისძიებების ფოტოგრაფია: ვაფიქსირებთ თქვენს
          მნიშვნელოვან მომენტებს ისე, რომ ისინი სამუდამოდ დარჩეს სამახსოვროდ.
          <br />
          • პროდუქტის ფოტოგრაფია: თუ გჭირდებათ პროდუქტის რეკლამისთვის შესაფერისი
          ფოტოები, ჩვენ გთავაზობთ კრეატიულ და ვიზუალურად ეფექტურ
          გადაწყვეტილებებს.
          <br />• სპეციალური თემატური სესიები: საბავშვო, სეზონური და უნიკალური
          კონცეფციების გადაღებები.
        </p>

        {/*         <p className="about-highlight">
          ჩვენთან სამყარო ყოველთვის ზღაპრულია! 🎄✨
        </p> */}
      </div>
    </div>
  );
};

export default About;
